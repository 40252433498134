import React from "react";
import { Tabs, Typography, Divider } from "antd";
import "./fiat.less";
import ExchangeTable from "./exchangeTreasuriesTable";
import FeesTable from "./feesTable";
const { Text } = Typography;

const { TabPane } = Tabs;

const Fiat = () => {
  return (
    <Tabs defaultActiveKey="1">
      <TabPane
        tab={
          <Text
            style={{
              fontSize: 18,
              color: "#fff",
            }}
          >
            Global
          </Text>
        }
        key="1"
      >
        <Divider
          orientation="left"
          style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
        >
          Exchange Treasuries Accounts
        </Divider>
        <ExchangeTable source={"Global"} />
        <br />
        <Divider
          orientation="left"
          style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
        >
          Fee's Accounts
        </Divider>
        <FeesTable source={"Global"} />
      </TabPane>
      <TabPane
        tab={
          <Text
            style={{
              fontSize: 18,
              color: "#fff",
            }}
          >
            Damex
          </Text>
        }
        key="2"
      >
        <Divider
          orientation="left"
          style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
        >
          Exchange Treasuries Accounts
        </Divider>
        <ExchangeTable source={"Damex"} />
        <br />
        <Divider
          orientation="left"
          style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
        >
          Fee's Accounts
        </Divider>
        <FeesTable source={"Damex"} />
      </TabPane>
      <TabPane
        tab={
          <Text
            style={{
              fontSize: 18,
              color: "#fff",
            }}
          >
            Damex Direct
          </Text>
        }
        key="3"
      >
        <Divider
          orientation="left"
          style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
        >
          Exchange Treasuries Accounts
        </Divider>
        <ExchangeTable source={"Damex Direct"} />
        <br />
        <Divider
          orientation="left"
          style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
        >
          Fee's Accounts
        </Divider>
        <FeesTable source={"Damex Direct"} />
      </TabPane>
    </Tabs>
  );
};
export default Fiat;
