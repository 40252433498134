import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Row,
  Col,
  Space,
  message,
  Spin,
} from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { authUser } from "../../redux/actions/authActions";
import { loginTwoFactor, userTwoFactor } from "../../redux/actions/twoFactorActions";
import {
  currentToken,
  currentClientId,
} from "../../redux/actions/tokenActions";
import servicesTwoFactor from "../../services/twoFactor";
import servicesUser from "../../services/user";

import ModalTwoFactor from "./twoFactorSignin";
import { v4 as uuidv4 } from "uuid";
import {
  osVersion,
  osName,
  fullBrowserVersion,
  browserName,
} from "react-device-detect";
import config from "../../services/config";
import { siderNavigate } from "../../redux/actions/navigateActions";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

import "./signin.less";

const fpPromise = FingerprintJS.load();

const { Title } = Typography;
const Signin = (props) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const [state, setState] = useState({
    auth: false,
    loading: false,
    idClient: "",
    email: ""
  });

  const history = useHistory();
  const { auth } = useSelector((state) => state);
  useState(() => {}, [auth]);

  const handleInput = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleSignin = () => {
    window.localStorage.setItem("success", true);
    history.push("/dashboard");
  };

  
  function openModalActivate() {
    setState({ ...state, modalActivate: true });
  }

  function closeModalActivate() {
      setState({ ...state, modalActivate: false });
      setIsDisabled(false);
  }

  async function  verificationCodeR(value) {
    try {
      let clientId = window.localStorage.getItem("client");
      let body = {
          email: state.email,
          otp: value
      };

      const response = await servicesTwoFactor.loginTwoFactor(clientId, body);
      await props.loginTwoFactor(response);
      props.currentToken(response.headers["x-new-access-token"]);
      
      if(response?.status === 200){          
        handleSignin()
      }    
    } catch (error) {
      if (error.response.headers["x-new-access-token"] !== undefined) {
      props.currentToken(error.response.headers["x-new-access-token"]);
      }
      if (error.response.status === 403) {
          message.error("Code Expired");
      } else if (error.response.status === 400) {
          message.error("Data Error");
      } else if (error.response.status === 401) {
          message.error("Incorrect code");
      } else if (error.response.status === 404) {
          message.error("Inactive User");
      } else {
          message.error("Server Error. Please try again");
      }
    }
  }

  async function getClientID() {
    const fp = await fpPromise;
    const result = await fp.get();
    const visitorId = result.visitorId;
    localStorage.setItem("client", visitorId);
    props.currentClientId(visitorId);
    setState({
      ...state,
      idClient: visitorId,
    });
    return visitorId
  }


  const onFinish = async (values) => {
    setIsDisabled(true);
    const id = await getClientID();
    let tokenId = uuidv4();
    let data = values.passwrd;
    let enumData = config.ENUM;
    let pass = new Buffer(data + enumData + tokenId);
    let passwordToBase64 = pass.toString("base64");
    let osInfo = osName + " " + osVersion;
    let browser = browserName + " " + fullBrowserVersion;
    let tokenFireBase = window.localStorage.getItem("token");
    let body = {
      email: values.username,
      password: passwordToBase64,
    };

    try {      
      const response = await servicesUser.userAuth(
        body,
        id,
        osInfo,
        browser,
        tokenFireBase
      );
      await props.authUser( response );
      await props.userTwoFactor( response?.data?.data.two_factor );

      if(response?.data?.data.two_factor){
        openModalActivate();
      }else{
        props.currentToken(response.headers["x-new-access-token"]);
        handleSignin();
      }
    } catch (error) {
      setIsDisabled(false);
      setState({ loading: false });
      if(error.response.status === 404){
        message.error("Invalid Email or Password");
      }else {
        message.error("An error has ocurred. Please try again");
      }
    }
  };

  return (
    <>    
      <Row gutter={[0, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col
          xs={{ span: 20, offset: 2 }}
          sm={{ span: 16, offset: 4 }}
          md={{ span: 14, offset: 5 }}
          lg={{ span: 6, offset: 9 }}
          xl={{ span: 6, offset: 9 }}
        >
          <Row justify="center" align="middle">
            <Col span={24}>
              <Space direction="vertical" className="container-login">
                <Title
                  level={4}
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginTop: 20,
                  }}
                >
                  Damex Admin
                </Title>
                <br />
                <Form
                  name="normal_login"
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Username can't be blank",
                      },
                      {
                        type: "email",
                        message: "Invalid E-mail",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      name="email"
                      disabled={isDisabled}
                      onChange={handleInput}
                      placeholder="Username"
                    />
                  </Form.Item>
                  <Form.Item
                    name="passwrd"
                    rules={[
                      {
                        required: true,
                        message: "Password can't be blank",
                      },
                    ]}
                  >
                    <Input.Password
                      size="large"
                      name="password"
                      disabled={isDisabled}
                      placeholder="Password"
                      onChange={handleInput}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      disabled={isDisabled}
                      className="login-form-button"
                      style={{ marginRight: "15px" }}
                    >
                      Sign In
                    </Button>
                    {isDisabled ? <Spin /> : null}
                  </Form.Item>
                </Form>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    
      <ModalTwoFactor
        openModal={openModalActivate.bind(this)}
        closeModal={closeModalActivate.bind(this)}
        verificationCodeReceived={verificationCodeR.bind(this)}
        modalActivate={state.modalActivate}
      />
    </>
    
  );
};
const mapStateToProps = (state) => {
  return {
    authUserResult: state.authUser.authResult,
    headers: state.authUser.headers,
    result: state.siderNavigate,
    changeTwoFactor: state.twoFactor,
    token: state.tokenAccess.current
  };
};

const mapDispatchToProps = {
  authUser,
  currentToken,
  currentClientId,
  siderNavigate,
  loginTwoFactor,
  userTwoFactor
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
