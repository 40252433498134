import React, { useState } from "react";
import { Tabs, Typography } from "antd";
import "./transactions.less";
import DepositsTable from "./transactionsTable";
import { connect } from "react-redux";
import { getTransactions } from "../../redux/actions/transactionActions";
import { currentToken } from "../../redux/actions/tokenActions";

const { Text } = Typography;

const { TabPane } = Tabs;

const Transactions = (props) => {
  const [active, setActive] = useState("1");

  return (
    <Tabs
      defaultActiveKey="1"
      onTabClick={(key) => {
        setActive(key);
      }}
      destroyInactiveTabPane={true}
    >
      <TabPane
        destroyInactiveTabPane={true}
        tab={
          <Text
            style={{
              fontSize: 18,
              color: "#fff",
            }}
          >
            Global
          </Text>
        }
        key={"1"}
      >
        {active === "1" && <DepositsTable source={"Global"} />}
      </TabPane>
      <TabPane
        destroyInactiveTabPane={true}
        tab={
          <Text
            style={{
              fontSize: 18,
              color: "#fff",
            }}
          >
            Damex
          </Text>
        }
        key={"2"}
      >
        {active === "2" && <DepositsTable source={"Damex"} />}
      </TabPane>
      <TabPane
        destroyInactiveTabPane={true}
        tab={
          <Text
            style={{
              fontSize: 18,
              color: "#fff",
            }}
          >
            Damex Direct
          </Text>
        }
        key={"3"}
      >
        {active === "3" && <DepositsTable source={"Damex Direct"} />}
      </TabPane>
    </Tabs>
  );
};

const mapStateToProps = (state) => {
  return {
    transactionResult: state.transaction.transactionResponse,
    token: state.tokenAccess.current,
    clientId: state.tokenAccess.clientId,
  };
};

const mapDispatchToProps = {
  getTransactions,
  currentToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
