import React from "react";
import { Tabs, Typography } from "antd";
import "./freezeWithdrawals.less";
import FreezeTable from "./freezeTable";
const { Text } = Typography;

const { TabPane } = Tabs;

const FreezeWithdrawals = () => {
  return (
    <Tabs defaultActiveKey="1">
      <TabPane
        tab={
          <Text
            style={{
              fontSize: 18,
              color: "#fff",
            }}
          >
            Damex
          </Text>
        }
        key="1"
      >
        <FreezeTable />
      </TabPane>
      <TabPane
        tab={
          <Text
            style={{
              fontSize: 18,
              color: "#fff",
            }}
          >
            Damex Direct
          </Text>
        }
        key="2"
      >
        <FreezeTable />
      </TabPane>
    </Tabs>
  );
};
export default FreezeWithdrawals;
