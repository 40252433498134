import React from "react";
import { Tabs, Typography } from "antd";
import "./exchange.less";
import ExchangeTable from "./exchangeTable";
const { Text } = Typography;

const { TabPane } = Tabs;

const Exchange = () => {
  return (
    <Tabs defaultActiveKey="1">
      <TabPane
        tab={
          <Text
            style={{
              fontSize: 18,
              color: "#fff",
            }}
          >
            Damex
          </Text>
        }
        key="1"
      >
          <ExchangeTable source={"Damex"} />
      </TabPane>
      <TabPane
        tab={
          <Text
            style={{
              fontSize: 18,
              color: "#fff",
            }}
          >
            Damex Direct
          </Text>
        }
        key="2"
      >
          <ExchangeTable source={"Damex Direct"} />
      </TabPane>
    </Tabs>
  );
};
export default Exchange;
