import React from "react";
import { Tabs, Typography } from "antd";
import "./cards.less";
import CardsTable from "./cardsTable";
const { Text } = Typography;

const { TabPane } = Tabs;

const Cards = () => {
 
  return (
    <Tabs defaultActiveKey="1">
      <TabPane
        tab={
          <Text
            style={{
              fontSize: 18,
              color: "#fff",
            }}
          >
            Damex
          </Text>
        }
        key="1"
      >
        <CardsTable  />
      </TabPane>
    </Tabs>
  );
};
export default Cards;
