import React from "react";
import { Tabs, Typography, Divider } from "antd";
import "./crypto.less";
import WithdrawalVaultsTable from "./withdrawalVaultsTable";
import OmnibusTable from "./omnibusTable";
const { Text } = Typography;

const { TabPane } = Tabs;

const Crypto = () => {
  return (
    <Tabs defaultActiveKey="1">
      <TabPane
        tab={
          <Text
            style={{
              fontSize: 18,
              color: "#fff",
            }}
          >
            Global
          </Text>
        }
        key="1"
      >
        <Divider
          orientation="left"
          style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
        >
          Withdrawal Vaults
        </Divider>
        <WithdrawalVaultsTable />
        <br />
        <Divider
          orientation="left"
          style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
        >
          Omnibus (Deposits) Vaults
        </Divider>
        <OmnibusTable />
      </TabPane>
      <TabPane
        tab={
          <Text
            style={{
              fontSize: 18,
              color: "#fff",
            }}
          >
            Damex
          </Text>
        }
        key="2"
      >
        <Divider
          orientation="left"
          style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
        >
          Withdrawal Vaults
        </Divider>
        <WithdrawalVaultsTable />
        <br />
        <Divider
          orientation="left"
          style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
        >
          Omnibus (Deposits) Vaults
        </Divider>
        <OmnibusTable />
      </TabPane>
      <TabPane
        tab={
          <Text
            style={{
              fontSize: 18,
              color: "#fff",
            }}
          >
            Damex Direct
          </Text>
        }
        key="3"
      >
        <Divider
          orientation="left"
          style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
        >
          Withdrawal Vaults
        </Divider>
        <WithdrawalVaultsTable />
        <br />
        <Divider
          orientation="left"
          style={{ color: "#fff", borderColor: "#fff", fontSize: 20 }}
        >
          Omnibus (Deposits) Vaults
        </Divider>
        <OmnibusTable />
      </TabPane>
    </Tabs>
  );
};
export default Crypto;
