const config = {
  ENUM: process.env.REACT_APP_ENUM,
  URL: process.env.REACT_APP_API_ENDPOINT,
  //--- Access
  login: "/auth",
  logout: "/auth/logout",
  //--- Balance
  balance: "/balance",
  //--- Refresh token
  refreshToken: "/auth/refresh",
  //--- Two factor
  activateTwoFactor: "/two-factor",
  confirmTwoFactor: "/two-factor/confirm",
  authTwoFactor: "/auth/two-factor",
  transaction: "/transactions",
  //--- Users
  users: "/damex-users/business-users",
  allUsers: "/damex-users/all-users",
  commissionersOrIntroducers: "/commissioners-or-introducers",
  //--- OTC
  accounts: "/damex-users/business-user-accounts/",
  createTransaction: "/transactions/create-transaction-otc",
  confirmTransaction: "/transactions/confirm-transaction-otc",
  //--- Currencies
  supportedCurrencies: "/supported-currencies",
  //--- Finance
  commissions: "/commissioners-or-introducers/commissions",
};

export default config;
