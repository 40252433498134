import React from "react";
import { Tabs, Typography } from "antd";
import "./profile.less";
import Security from "./security";
const { Text } = Typography;

const { TabPane } = Tabs;

const Profile = () => {
 
  return (
    <Tabs defaultActiveKey="1">
      <TabPane
        tab={
          <Text
            style={{
              fontSize: 18,
              color: "#fff",
            }}
          >
            Security
          </Text>
        }
        key="1"
      >
          <Security />

      </TabPane>
    </Tabs>
  );
};
export default Profile;
