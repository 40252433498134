import servicesUser from "../../services/user";
import servicesOtc from "../../services/otc";
import { currentToken } from "./tokenActions";
export const USERS = "USERS";
export const ACCOUNTS = "ACCOUNTS";
export const CREATE_TRANSACTION = "CREATE_TRANSACTION";
export const CONFIRM_TRANSACTION = "CONFIRM_TRANSACTION";
export const CURRENCIES="CURRENCIES"

export function usersDamex(id, accessToken, lastID) {
  return async (dispatch, getState) => {
    const response = await servicesUser.usersList(id, accessToken, lastID);
    if (response.headers["x-new-access-token"] !== undefined) {
      dispatch(currentToken(response.headers["x-new-access-token"]));
    }
    dispatch({
      type: USERS,
      payload: response,
    });
  };
}
export function clearUsersDamex() {
  return (dispatch, getState) => {
    dispatch({
      type: USERS,
      payload: {},
    });
  };
}
export function userAccounts(id, accessToken, userId) {
  return async (dispatch, getState) => {
    const response = await servicesUser.userAccount(id, accessToken, userId);
    if (response.headers["x-new-access-token"] !== undefined) {
      dispatch(currentToken(response.headers["x-new-access-token"]));
    }
    dispatch({
      type: ACCOUNTS,
      payload: response,
    });
  };
}
export function clearAccounts() {
  return (dispatch, getState) => {
    dispatch({
      type: ACCOUNTS,
      payload: {},
    });
  };
}
export function createTransaction(id, accessToken, body) {
  return async (dispatch, getState) => {
    const response = await servicesOtc.createTransactionOtc(
      id,
      accessToken,
      body
    );
    if (response.headers["x-new-access-token"] !== undefined) {
      dispatch(currentToken(response.headers["x-new-access-token"]));
    }
    dispatch({
      type: CREATE_TRANSACTION,
      payload: response,
    });
  };
}
export function confirmTransaction(id, accessToken, body) {
  return async (dispatch, getState) => {
    const response = await servicesOtc.confirmTransactionOtc(
      id,
      accessToken,
      body
    );
    if (response.headers["x-new-access-token"] !== undefined) {
      dispatch(currentToken(response.headers["x-new-access-token"]));
    }
    dispatch({
      type: CONFIRM_TRANSACTION,
      payload: response,
    });
  };
}
export function getCurrencies(currencies) {
  return async (dispatch, getState) => {
    // const response = await servicesOtc.getCurrencies(id, accessToken);
    // if (response.headers["x-new-access-token"] !== undefined) {
    //   dispatch(currentToken(response.headers["x-new-access-token"]));
    // }
    dispatch({
      type: CURRENCIES,
      payload: currencies,
    });
  };
}
